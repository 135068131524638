define("discourse/plugins/discourse-mentionables/discourse/components/mentionables-info", ["exports", "@ember/component", "discourse/plugins/discourse-mentionables/discourse/mixins/mentionables-dropdown"], function (_exports, _component, _mentionablesDropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(_mentionablesDropdown.default, {
    classNames: ["mentionables-info", "mentionables-dropdown"]
  });
});