define("discourse/plugins/discourse-mentionables/discourse/lib/mentionable-item-search", ["exports", "discourse/plugins/discourse-mentionables/discourse/models/mentionable-item"], function (_exports, _mentionableItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.searchMentionableItem = searchMentionableItem;
  let cache = {};
  let cacheTime;
  let oldSearch;
  function updateCache(term, results) {
    cache[term] = results;
    cacheTime = new Date();
    return results;
  }
  function searchMentionableItem(term, siteSettings) {
    if (oldSearch) {
      oldSearch.abort();
      oldSearch = null;
    }
    if (new Date() - cacheTime > 30000) {
      cache = {};
    }
    const cached = cache[term];
    if (cached) {
      return cached;
    }
    const limit = siteSettings.mentionables_autocomplete_limit;
    let mentionable_items = _mentionableItem.default.search(term, {
      limit
    });
    mentionable_items = mentionable_items.map(item => {
      return {
        model: item,
        text: _mentionableItem.default.nameFor(item)
      };
    });
    return updateCache(term, mentionable_items);
  }
});