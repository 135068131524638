define("discourse/plugins/discourse-mentionables/discourse/tests/fixtures/mentionable-fixtures", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "mentionable_items.json": {
      mentionable_items: [{
        affiliate_snippet_1: "",
        affiliate_snippet_2: "",
        affiliate_snippet_3: "",
        created_at: "2021-11-03T17:35:14.474Z",
        description: "The Stuff of Dreams book",
        id: 1,
        image_url: null,
        name: "The Stuff of Dreams",
        slug: "the-stuff-of-dreams",
        updated_at: "2021-11-03T17:35:14.474Z",
        url: "https://amazing.com/stuff-of-dreams-book"
      }]
    }
  };
});