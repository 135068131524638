define("discourse/plugins/discourse-mentionables/discourse/models/mentionable-item", ["exports", "discourse-common/utils/decorators", "discourse/models/rest", "discourse/models/site", "@ember/object"], function (_exports, _decorators, _rest, _site, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MentionableItem = _rest.default.extend(dt7948.p({
    searchContext(id) {
      return {
        type: "mentionable_item",
        id,
        mentionable_item: this
      };
    }
  }, [["method", "searchContext", [(0, _decorators.default)("id")]]]));
  MentionableItem.reopenClass({
    nameFor(item) {
      if (!item) {
        return "";
      }
      let result = "";
      const id = (0, _object.get)(item, "id");
      const name = (0, _object.get)(item, "name");
      return !name || name.trim().length === 0 ? `${result}${id}-mentionable_item` : result + name;
    },
    list() {
      return _site.default.currentProp("mentionable_items");
    },
    _idMap() {
      return _site.default.currentProp("mentionable_items");
    },
    findById(id) {
      if (!id) {
        return;
      }
      return MentionableItem._idMap()[id];
    },
    findByIds() {
      let ids = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      const mentionable_items = [];
      ids.forEach(id => {
        const found = MentionableItem.findById(id);
        if (found) {
          mentionable_items.push(found);
        }
      });
      return mentionable_items;
    },
    search(term, opts) {
      let limit = 5;
      if (opts) {
        if (opts.limit === 0) {
          return [];
        } else if (opts.limit) {
          limit = opts.limit;
        }
      }
      const emptyTerm = term === "";
      if (!emptyTerm) {
        term = term.toLowerCase();
      }
      const mentionable_items = MentionableItem.list();
      const length = mentionable_items.length;
      let i;
      let data = [];
      const done = () => {
        return data.length === limit;
      };
      for (i = 0; i < length && !done(); i++) {
        const mentionable_item = mentionable_items[i];
        if (!emptyTerm && mentionable_item.name.toLowerCase().indexOf(term) >= 0 || mentionable_item.slug && mentionable_item.slug.toLowerCase().indexOf(term) >= 0) {
          data.push(mentionable_item);
        }
      }
      return data.sortBy("slug");
    }
  });
  var _default = _exports.default = MentionableItem;
});