define("discourse/plugins/discourse-mentionables/discourse/tests/acceptance/mentionable-in-composer-test", ["@ember/test-helpers", "discourse/controllers/composer", "discourse-common/lib/object", "discourse/tests/helpers/qunit-helpers", "qunit", "discourse/plugins/discourse-mentionables/discourse/tests/fixtures/mentionable-fixtures"], function (_testHelpers, _composer, _object, _qunitHelpers, _qunit, _mentionableFixtures) {
  "use strict";

  (0, _qunitHelpers.acceptance)("Composer", function (needs) {
    needs.user({
      id: 5,
      username: "kris",
      whisperer: true
    });
    needs.settings({
      general_category_id: 1,
      default_composer_category: 1
    });
    needs.site({
      categories: [{
        id: 1,
        name: "General",
        slug: "general",
        permission: 1,
        topic_template: null
      }]
    });
    needs.site((0, _object.cloneJSON)(_mentionableFixtures.default["mentionable_items.json"]));
    needs.hooks.afterEach(() => (0, _composer.toggleCheckDraftPopup)(false));
    (0, _qunit.test)("composer controls", async function (assert) {
      await (0, _testHelpers.visit)("/");
      assert.ok((0, _qunitHelpers.exists)("#create-topic"), "the create button is visible");
      await (0, _testHelpers.click)("#create-topic");
      assert.ok((0, _qunitHelpers.exists)(".d-editor-input"), "the composer input is visible");
      assert.ok((0, _qunitHelpers.exists)(".d-editor-preview:visible"), "shows the preview");
      await (0, _testHelpers.fillIn)("#reply-title", "this is my new topic title");
      assert.ok((0, _qunitHelpers.exists)(".title-input .popup-tip.good.hide"), "the title is now good");
      await (0, _testHelpers.fillIn)(".d-editor-input", "this is the *content* of a post with a mentionable item +the-stuff-of-dreams");
      assert.strictEqual((0, _qunitHelpers.query)(".d-editor-preview").innerHTML.trim(), '<p>this is the <em>content</em> of a post with a mentionable item <a href="https://amazing.com/stuff-of-dreams-book" class="mentionable-item" target="_blank" tabindex="-1"><span>The Stuff of Dreams</span></a></p>', "it previews content");
    });
  });
});