define("discourse/plugins/discourse-mentionables/discourse/mixins/mentionables-dropdown", ["exports", "@ember/object/mixin", "@ember/runloop"], function (_exports, _mixin, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _mixin.default.create({
    showDetails: false,
    didInsertElement() {
      $(document).on("click", (0, _runloop.bind)(this, this.documentClick));
    },
    willDestroyElement() {
      $(document).off("click", (0, _runloop.bind)(this, this.documentClick));
    },
    documentClick(e) {
      if (this._state === "destroying") {
        return;
      }
      if (!$(e.target).closest(this.element).length) {
        this.set("showDetails", false);
      }
    },
    actions: {
      toggleDetails() {
        this.toggleProperty("showDetails");
      }
    }
  });
});