define("discourse/plugins/discourse-mentionables/discourse/routes/admin-plugins-mentionables", ["exports", "discourse/plugins/discourse-mentionables/discourse/models/mentionables-log", "discourse/routes/discourse", "@ember/array"], function (_exports, _mentionablesLog, _discourse, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model() {
      return _mentionablesLog.default.list();
    },
    setupController(controller, model) {
      const logs = (0, _array.A)(model.logs.map(log => _mentionablesLog.default.create(log)));
      const info = model.info;
      controller.setProperties({
        logs,
        info
      });
    }
  });
});