define("discourse/plugins/discourse-mentionables/discourse/components/mentionables-log-message", ["exports", "@ember/component", "discourse-common/utils/decorators", "discourse/plugins/discourse-mentionables/discourse/mixins/mentionables-dropdown", "I18n"], function (_exports, _component, _decorators, _mentionablesDropdown, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const typesWithDescriptions = ["destroy_all"];
  var _default = _exports.default = _component.default.extend(_mentionablesDropdown.default, dt7948.p({
    classNames: ["mentionables-log-message", "mentionables-dropdown"],
    showDetails: false,
    messageTitle(type) {
      return _I18n.default.t(`mentionables.log.${type}.title`);
    },
    messageDetails(type, message) {
      if (type === "report") {
        return this.reportDetails(message);
      }
      if (typesWithDescriptions.includes(type)) {
        return _I18n.default.t(`mentionables.log.${type}.description`);
      }
      return message;
    },
    reportDetails(message) {
      return Object.keys(message).map(key => {
        let opts = {};
        if (/\_items/.test(key)) {
          opts.items = message[key].join(", ");
        } else {
          opts.count = message[key];
        }
        return _I18n.default.t(`mentionables.log.report.${key}`, opts);
      });
    }
  }, [["method", "messageTitle", [(0, _decorators.default)("log.type")]], ["method", "messageDetails", [(0, _decorators.default)("log.type", "log.message")]]]));
});