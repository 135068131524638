define("discourse/plugins/discourse-mentionables/discourse/templates/components/mentionables-info", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <a {{action "toggleDetails"}} role="button" title={{i18n "mentionables.info.title"}}>
    {{d-icon "info-circle"}}
    {{i18n "mentionables.info.title"}}
  </a>
  {{#if showDetails}}
    <div class="details">
      {{i18n "mentionables.info.total" total=info.total}}
    </div>
  {{/if}}
  
  */
  {
    "id": "3tNf7VYw",
    "block": "[[[11,3],[24,\"role\",\"button\"],[16,\"title\",[28,[37,0],[\"mentionables.info.title\"],null]],[4,[38,1],[[30,0],\"toggleDetails\"],null],[12],[1,\"\\n  \"],[1,[28,[35,2],[\"info-circle\"],null]],[1,\"\\n  \"],[1,[28,[35,0],[\"mentionables.info.title\"],null]],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[30,0,[\"showDetails\"]],[[[1,\"  \"],[10,0],[14,0,\"details\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"mentionables.info.total\"],[[\"total\"],[[30,0,[\"info\",\"total\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,[28,[32,0],[\"[[\\\"The `showDetails` property path was used in the `discourse/plugins/discourse-mentionables/discourse/templates/components/mentionables-info.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.showDetails}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `info` property path was used in the `discourse/plugins/discourse-mentionables/discourse/templates/components/mentionables-info.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.info}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"i18n\",\"action\",\"d-icon\",\"if\"]]",
    "moduleName": "discourse/plugins/discourse-mentionables/discourse/templates/components/mentionables-info.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});