define("discourse/plugins/discourse-mentionables/discourse/controllers/admin-plugins-mentionables", ["exports", "discourse-common/utils/decorators", "@ember/object/computed", "discourse/plugins/discourse-mentionables/discourse/models/mentionables-log", "@ember/controller", "discourse/lib/debounce", "discourse-common/config/environment", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/array", "I18n"], function (_exports, _decorators, _computed, _mentionablesLog, _controller, _debounce, _environment, _ajax, _ajaxError, _array, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const mentionablesPath = "/admin/plugins/mentionables";
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    refreshing: false,
    hasLogs: (0, _computed.notEmpty)("logs"),
    page: 0,
    canLoadMore: true,
    logs: [],
    loadLogs: (0, _debounce.default)(function () {
      if (!this.canLoadMore) {
        return;
      }
      this.set("refreshing", true);
      const page = this.page;
      let params = {
        page
      };
      const filter = this.filter;
      if (filter) {
        params.filter = filter;
      }
      _mentionablesLog.default.list(params).then(result => {
        const logs = result.logs;
        const info = result.info;
        if (!logs || logs.length === 0) {
          this.set("canLoadMore", false);
        }
        if (filter && page === 0) {
          this.set("logs", (0, _array.A)());
        }
        this.get("logs").pushObjects(logs.map(l => _mentionablesLog.default.create(l)));
        this.set("info", info);
      }).finally(() => this.set("refreshing", false));
    }, _environment.INPUT_DELAY),
    noResults(hasLogs, refreshing) {
      return !hasLogs && !refreshing;
    },
    showMessage(key) {
      this.set("message", _I18n.default.t(`mentionables.${key}`));
      setTimeout(() => {
        this.set("message", null);
      }, 20000);
    },
    actions: {
      loadMore() {
        let currentPage = this.get("page");
        this.set("page", currentPage += 1);
        this.loadLogs();
      },
      refresh() {
        this.setProperties({
          canLoadMore: true,
          page: 0,
          logs: []
        });
        this.loadLogs();
      },
      startImport() {
        (0, _ajax.ajax)(mentionablesPath, {
          type: "POST"
        }).then(result => {
          this.showMessage(result.success ? "import_started" : "error");
        }).catch(_ajaxError.popupAjaxError).finally(() => {
          this.set("loading", false);
        });
      },
      deleteData() {
        (0, _ajax.ajax)(mentionablesPath, {
          type: "DELETE"
        }).then(result => {
          this.showMessage(result.success ? "data_deleted" : "error");
        }).catch(_ajaxError.popupAjaxError).finally(() => {
          this.set("loading", false);
        });
      }
    }
  }, [["field", "loadLogs", [(0, _decorators.observes)("filter")]], ["method", "noResults", [(0, _decorators.default)("hasLogs", "refreshing")]]]));
});