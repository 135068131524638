define("discourse/plugins/discourse-mentionables/discourse/models/mentionables-log", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/object", "@ember/object/computed"], function (_exports, _ajax, _ajaxError, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MentionablesLog = _object.default.extend({
    isReport: (0, _computed.equal)("type", "report")
  });
  MentionablesLog.reopenClass({
    list() {
      let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return (0, _ajax.ajax)("/admin/plugins/mentionables", {
        data: params
      }).catch(_ajaxError.popupAjaxError);
    }
  });
  var _default = _exports.default = MentionablesLog;
});