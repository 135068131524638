define("discourse/plugins/discourse-mentionables/discourse/initializers/mentionables", ["exports", "discourse/lib/plugin-api", "discourse-common/lib/raw-templates", "discourse/plugins/discourse-mentionables/discourse/lib/mentionable-item-search", "discourse/plugins/discourse-mentionables/discourse/lib/mentionable-items-preview-styling", "discourse/plugins/discourse-mentionables/discourse/lib/discourse-markdown/mentionable-items", "@ember/object", "discourse/lib/utilities", "discourse/models/site", "@ember/runloop"], function (_exports, _pluginApi, _rawTemplates, _mentionableItemSearch, _mentionableItemsPreviewStyling, _mentionableItems, _object, _utilities, _site, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "mentionable-items",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (!siteSettings.mentionables_enabled) {
        return;
      }
      const site = _site.default.current();
      const mentionableItems = site.mentionable_items;
      if (!site || !mentionableItems) {
        return;
      }
      const length = mentionableItems.length;
      const obj = _object.default.create(mentionableItems);
      (0, _object.set)(obj, "length", length);
      _site.default.current().set("mentionable_items", obj);
      (0, _pluginApi.withPluginApi)("0.8.13", api => {
        api.modifyClass("component:d-editor", {
          pluginId: "discourse-mentionables",
          didInsertElement() {
            this._super(...arguments);
            const $editorInput = $(this.element.querySelector(".d-editor-input"));
            this._applyMentionableItemsAutocomplete($editorInput);
          },
          _applyMentionableItemsAutocomplete($editorInput) {
            $editorInput.autocomplete({
              template: (0, _rawTemplates.findRawTemplate)("mentionable-item-autocomplete"),
              key: _mentionableItems.SEPARATOR,
              afterComplete: value => {
                this.set("value", value);
                return this.focusTextArea();
              },
              transformComplete: item => item.model.slug,
              dataSource: term => term.match(/\s/) ? null : (0, _mentionableItemSearch.searchMentionableItem)(term, siteSettings),
              triggerRule: textarea => !(0, _utilities.inCodeBlock)(textarea.value, (0, _utilities.caretPosition)(textarea))
            });
          },
          _updatePreview() {
            this._super(...arguments);
            (0, _runloop.schedule)("afterRender", () => {
              const preview = this.element.querySelector(".d-editor-preview");
              if (this._state !== "inDOM" || preview.length === 0) {
                return;
              }
              (0, _mentionableItemsPreviewStyling.linkSeenMentionableItems)(preview, siteSettings);
            });
          }
        });
        api.onToolbarCreate(toolbar => {
          toolbar.addButton({
            id: "insert-mentionable",
            group: "extras",
            icon: siteSettings.mentionables_composer_button_icon,
            title: "mentionables.composer.insert.title",
            perform: e => {
              e.addText(_mentionableItems.SEPARATOR);
              $(document.querySelector("#reply-control .d-editor-input")).trigger("keyup.autocomplete");
            }
          });
        });
      });
    }
  };
});